<template>
  <v-row
    class="px-2 my-2"
    align="center"
  >
    <v-col
      cols="12"
      md="3"
      class="py-4"
    >
      <v-text-field
        v-model="search"
        placeholder="Search"
        outlined
        hide-details
        dense
        class="user-search me-3"
      >
      </v-text-field>
    </v-col>
    <v-col
      cols="12"
      class="pa-0"
    >
      <v-data-table
        :headers="headers"
        :items="shippingInfoList"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <ShippingInfoEdit
              :id="item.sf_id"
              class="py-2 pl-1"
              @refresh="loadData"
              @click="editItem(item)"
            >
            </ShippingInfoEdit>
          </div>
        </template>
        <template v-slot:[`item.s60`]="{ item }">
          <div>
            <span>{{ formatData(item.s60) }}</span>
          </div>
        </template>
        <template v-slot:[`item.s80`]="{ item }">
          <div>
            <span>{{ formatData(item.s80) }}</span>
          </div>
        </template>
        <template v-slot:[`item.s100`]="{ item }">
          <div>
            <span>{{ formatData(item.s100) }}</span>
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'
import {
  mdiPencil,
} from '@mdi/js'
import ShippingInfoEdit from '@/views/bimi/setting/Master/ShippingInfo/ShippingInfoEdit.vue'

// import agent from '@/api/agent'

export default {
  components: {
    ShippingInfoEdit,
  },
  data: () => ({
    icon: {
      mdiPencil,
    },
    search: '',
    headers: [
      {
        text: 'ID',
        align: 'left',
        value: 'sf_id',
        width: '6%',
        fixed: true,
      },
      {
        text: '地帯名称',
        align: 'left',
        value: 'sf_grp_name',
        width: '10%',
        fixed: true,
      },
      {
        text: '都道府県',
        align: 'left',
        value: 'pref_names',
        width: '36%',
        fixed: true,
      },
      {
        text: '箱サイズ60',
        align: 'right',
        value: 's60',
        width: '12%',
        fixed: true,
      },
      {
        text: '箱サイズ80',
        align: 'right',
        value: 's80',
        width: '12%',
        fixed: true,
      },
      {
        text: '箱サイズ100',
        align: 'right',
        value: 's100',
        width: '12%',
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '5%',
        align: 'right',
        fixed: true,
      },
    ],
  }),

  computed: {
    ...mapState('shippingStore', ['shippingInfoList']),
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
  },

  watch: {
  },

  mounted() {
    this.$emit('has-mounted')
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('shippingStore', ['loadShippingInfoList']),
    editItem(item) {
      console.log(item)
    },
    loadData() {
      this.setOverlayStatus(true)
      this.loadShippingInfoList().finally(() => {
        this.setOverlayStatus(false)
      })
    },
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
</style>
