<template>
  <v-row
    class="px-2 my-2"
    align="center"
  >
    <v-col
      cols="12"
      md="3"
      class="py-4"
    >
      <v-text-field
        v-model="search"
        placeholder="Search"
        outlined
        hide-details
        dense
        class="user-search me-3"
      >
      </v-text-field>
    </v-col>
    <v-spacer></v-spacer>
    <v-col
      cols="12"
      md="3"
      style="text-align:right"
    >
      <CouponNew
        @refresh="loadData"
      ></CouponNew>
    </v-col>
    <v-col
      cols="12"
      class="pa-0"
    >
      <v-data-table
        :headers="headers"
        :items="couponList"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <CouponEdit
              :id="item.id"
              class="py-2 pl-1"
              @refresh="loadData"
              @click="editItem(item)"
            >
            </CouponEdit>
            <CouponDelete
              :id="item.id"
              class="py-2 pl-1"
              @refresh="loadData"
            ></CouponDelete>
          </div>
        </template>
        <template v-slot:[`item.coup_discount`]="{ item }">
          <div>
            <span>{{ formatData(item.coup_discount) }}</span>
          </div>
        </template>
        <template v-slot:[`item.coup_type`]="{ item }">
          <div>
            <span>{{ getType(item.coup_type) }}</span>
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'
import {
  mdiPlus,
  mdiPencil,
  mdiDelete,
} from '@mdi/js'
import CouponNew from '@/views/bimi/setting/Master/Coupon/CouponNew.vue'
import CouponEdit from '@/views/bimi/setting/Master/Coupon/CouponEdit.vue'
import CouponDelete from '@/views/bimi/setting/Master/Coupon/CouponDelete.vue'

// import agent from '@/api/agent'

export default {
  components: {
    CouponNew,
    CouponEdit,
    CouponDelete,
  },
  data: () => ({
    icon: {
      mdiPlus,
      mdiPencil,
      mdiDelete,
    },
    search: '',
    types: [
      {
        text: '一回切り', value: 0,
      },
      {
        text: '永久', value: 9,
      },
    ],
    headers: [
      {
        text: 'ID',
        align: 'left',
        value: 'id',
        width: '10%',
        fixed: true,
      },
      {
        text: 'クーポンコード',
        align: 'left',
        value: 'coup_code',
        width: '15%',
        fixed: true,
      },
      {
        text: 'クーポン名前',
        align: 'left',
        value: 'coup_name',
        width: '20%',
        fixed: true,
      },
      {
        text: '割引値',
        align: 'right',
        value: 'coup_discount',
        width: '10%',
        fixed: true,
      },
      {
        text: '利用開始日',
        align: 'left',
        value: 'coup_vfd',
        width: '10%',
        fixed: true,
      },
      {
        text: '利用終了日',
        align: 'left',
        value: 'coup_ved',
        width: '10%',
        fixed: true,
      },

      // {
      //   text: 'タイプ',
      //   align: 'left',
      //   value: 'coup_type',
      //   width: '10%',
      //   fixed: true,
      // },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '5%',
        align: 'right',
        fixed: true,
      },
    ],
  }),

  computed: {
    ...mapState('couponStore', ['couponList']),
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
  },

  watch: {
  },

  mounted() {
    this.$emit('has-mounted')
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('couponStore', ['loadCouponList']),
    editItem(item) {
      console.log(item)
    },
    loadData() {
      this.setOverlayStatus(true)
      this.loadCouponList(this.$route.query).then(() => {
        // console.log('loadData1', this.couponList)
        this.couponList.sort((a, b) => b.id - a.id)
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },
    getType(type) {
      if (type === 0) {
        return '一回切り'
      }
      if (type === 9) {
        return '永久'
      }

      return '设定一下'
    },
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
</style>
