<template>
  <div justify="center">
    <v-icon
      small
      @click.stop="openForm"
    >
      {{ icons.mdiPencil }}
    </v-icon>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span
            class="text-h5"
            style="color: red"
          >コース-編集</span>
        </v-card-title>
        <template v-if="apierror.status == 'error'">
          <div
            v-for="msg of apierror.messages"
            :key="msg"
          >
            <v-row class="ml-6 mb-3 ma-3">
              <span style="color: red">* {{ msg }} </span>
            </v-row>
          </div>
        </template>
        <v-card-text>
          <v-container>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col cols="4" md="4">
                <span style="color:#636363">コース名前</span><span style="color: red">*</span>
              </v-col>
              <v-col
                cols="8"
              >
                <v-text-field
                  v-model="$v.CourseForm.course_name.$model"
                  outlined
                  :error-messages="courseNameErrors"
                  dense
                  hide-details="auto"
                  color="#fa7a00"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col cols="4" md="4">
                <span style="color:#636363">コースコード</span><span style="color: red">*</span>
              </v-col>
              <v-col
                cols="8"
              >
                <v-text-field
                  v-model="$v.CourseForm.course_code.$model"
                  :error-messages="courseCodeErrors"
                  outlined
                  dense
                  hide-details="auto"
                  color="#fa7a00"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions align="center" class="d-flex justify-center">
          <v-btn
            color="success"
            :loading="submitStatus"
            @click="submit(CourseForm)"
          >
            YES
          </v-btn>
          <v-btn
            class="mx-2"
            color="error"
            @click="closeForm()"
          >
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiPencil,
} from '@mdi/js'
import {
  mapMutations,
  mapActions,
  mapState,
} from 'vuex'
import {
  required,
  maxLength,
} from 'vuelidate/lib/validators'
import {
  helpers,
} from '@vuelidate/validators'

/* eslint-disable no-useless-escape */
const allowedChar = helpers.regex(/^[0-9A-z!"#$%&'()*+-.,\/:;<=>?@[\]^_`{|}~]*$/)

export default {
  components: {
  },
  props: ['id'],
  data: () => ({
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    dialog: false,
    icons: {
      mdiPencil,
    },
    dateStartMenu: '',
    dateEndMenu: '',
    submitStatus: false,
    CourseForm: {
      // course_code: '',
      // course_name: '',
      // course_disp_order: '',
      // course_id: '',
    },
  }),
  validations: {
    CourseForm: {
      course_name: {
        required,
        maxLength: maxLength(20),
      },
      course_code: {
        required,
        maxLength: maxLength(3),
        allowedChar,
      },
    },
  },
  computed: {
    ...mapState('subscriptionStore', ['selectedCourses']),
    courseNameErrors() {
      const errors = []
      if (!this.$v.CourseForm.course_name.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.CourseForm.course_name.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.CourseForm.course_name.maxLength && errors.push('20文字以内にしてください。')

      return errors
    },
    courseCodeErrors() {
      const errors = []
      if (!this.$v.CourseForm.course_code.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.CourseForm.course_code.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.CourseForm.course_code.maxLength && errors.push('3文字以内にしてください。')

      return errors
    },
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('subscriptionStore', ['loadCourses', 'editCourses']),
    openForm() {
      this.setOverlayStatus(true)
      this.loadCourses(this.id).then(() => {
        this.CourseForm = this.$deepCopy(this.selectedCourses)
      }).catch(error => {
        if (error.response) {
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        }
      }).finally(() => {
        this.dialog = true
        this.setOverlayStatus(false)
      })
    },
    closeForm() {
      this.dialog = false
      this.$emit('refresh')
    },

    submit(CourseForm) {
      // console.log('CourseForm:', CourseForm)

      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitStatus = true

        this.editCourses(CourseForm)
          .then(() => {
            this.closeForm()
          })
          .catch(error => {
            if (error.response) {
              this.apierror.status = 'error'
              this.apierror.code = error.response.data.code
              this.apierror.messages = error.response.data.message
            }
          })
          .finally(() => {
            this.submitStatus = false
          })
      }
    },
  },
}
</script>
