<template>
  <div justify="center">
    <v-btn
      color="primary"
      class="mr-2"
      @click.stop="openForm"
    >
      <v-icon
        size="17"
        class="me-1"
      >
        {{ icons.mdiPlus }}
      </v-icon>
      <span>新規</span>
    </v-btn>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span
            class="text-h5"
            style="color: red"
          >会員状態-新規</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="ms_desc"
                  label="会員状態"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="ms_code"
                  label="会員状態コード"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions align="center" class="d-flex justify-center">
          <v-btn
            color="success"
            @click="closeForm()"
          >
            YES
          </v-btn>
          <v-btn
            class="mx-2"
            color="error"
            @click="closeForm()"
          >
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiPlus,
} from '@mdi/js'

export default {
  data: () => ({
    dialog: false,
    icons: {
      mdiPlus,
    },
    ms_desc: '',
    ms_code: '',
  }),
  computed: {
  },
  methods: {
    openForm() {
      this.dialog = true
    },
    closeForm() {
      this.dialog = false
    },
  },
}
</script>
