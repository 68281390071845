var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"px-2 my-2",attrs:{"align":"center"}},[_c('v-col',{staticClass:"py-4",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{staticClass:"user-search me-3",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.shippingInfoList,"search":_vm.search,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('ShippingInfoEdit',{staticClass:"py-2 pl-1",attrs:{"id":item.sf_id},on:{"refresh":_vm.loadData,"click":function($event){return _vm.editItem(item)}}})],1)]}},{key:"item.s60",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(_vm.formatData(item.s60)))])])]}},{key:"item.s80",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(_vm.formatData(item.s80)))])])]}},{key:"item.s100",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(_vm.formatData(item.s100)))])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }