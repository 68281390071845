<template>
  <v-row
    class="px-2 my-2"
    align="center"
  >
    <v-col
      cols="12"
      md="3"
      class="py-4"
    >
      <v-autocomplete
        v-model="sf_grp_id"
        :items="areaListMaster"
        placeholder="地域"
        outlined
        clearable
        dense
        hide-details
      ></v-autocomplete>
    </v-col>
    <v-col
      cols="12"
      class="pa-0"
    >
      <v-data-table
        :headers="headers"
        :items="prefectureList"
        :search="search"
      >
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'
import {
  mdiPlus,
  mdiPencil,
  mdiDelete,
} from '@mdi/js'

export default {
  components: {
  },
  data: () => ({
    icon: {
      mdiPlus,
      mdiPencil,
      mdiDelete,
    },
    sf_grp_id: '',
    search: '',
    item_code: '',
    headers: [
      {
        text: '都道府県番号',
        align: 'left',
        value: 'pref_code',
        width: '20%',
        fixed: true,
      },
      {
        text: '都道府県',
        align: 'left',
        value: 'pref_name',
        width: '20%',
        fixed: true,
      },
      {
        text: '地域',
        align: 'left',
        value: 'sf_grp_name',
        width: '45%',
        fixed: true,
      },
      {
        text: '地域番号',
        value: 'sf_grp_id',
        align: ' d-none',
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '5%',
        align: 'right',
        fixed: true,
      },
    ],
  }),

  computed: {
    ...mapState('prefectureStore', ['prefectureList', 'areaListMaster']),
  },

  watch: {
    sf_grp_id(newValue) {
      this.changeRouteQuery('sf_grp_id', newValue)
      this.loadData()
    },
  },

  created() {
    this.loadQuery()
    this.changeRouteQuery()
  },

  mounted() {
    this.$emit('has-mounted')
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('prefectureStore', ['loadPrefectureList', 'loadAreaList']),
    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadPrefectureList(this.$route.query),
        this.loadAreaList(),
      ]).finally(() => {
        this.setOverlayStatus(false)
      })
    },
    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({
        path: this.$route.path, query,
      })
    },

    loadQuery() {
      // eslint-disable-next-line radix
      this.sf_grp_id = this.$route.query.sf_grp_id ? parseInt(this.$route.query.sf_grp_id) : ''
    },
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
</style>
