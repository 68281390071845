<template>
  <div justify="center">
    <v-icon
      small
      @click.stop="openForm"
    >
      {{ icons.mdiPencil }}
    </v-icon>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span
            class="text-h5"
            style="color: red"
          >運賃表-編集</span>
        </v-card-title>
        <template v-if="apierror.status == 'error'">
          <div
            v-for="msg of apierror.messages"
            :key="msg"
          >
            <v-row class="ml-6 mb-3 ma-3">
              <span style="color: red">* {{ msg }} </span>
            </v-row>
          </div>
        </template>
        <v-card-text>
          <v-container>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col cols="4" md="4">
                <span style="color:#636363">地帯名称</span>
              </v-col>
              <v-col
                cols="8"
              >
                <span style="color:#636363">{{ ShippingForm.sf_grp_name }}</span>
              </v-col>
            </v-row>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col cols="4" md="4">
                <span style="color:#636363">都道府県</span>
              </v-col>
              <v-col
                cols="8"
              >
                <span style="color:#636363">{{ ShippingForm.pref_names }}</span>
              </v-col>
            </v-row>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col cols="4" md="4">
                <span style="color:#636363">値段(サイズ60)</span>
              </v-col>
              <v-col
                cols="8"
              >
                <NumberInput
                  v-model="$v.ShippingForm.s60.$model"
                  :outlined="true"
                  :error-messages="s60Errors"
                  prefix="¥"
                ></NumberInput>
              </v-col>
            </v-row>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col cols="4" md="4">
                <span style="color:#636363">値段(サイズ80)</span>
              </v-col>
              <v-col
                cols="8"
              >
                <NumberInput
                  v-model="$v.ShippingForm.s80.$model"
                  :outlined="true"
                  :error-messages="s80Errors"
                  prefix="¥"
                ></NumberInput>
              </v-col>
            </v-row>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col cols="4" md="4">
                <span style="color:#636363">値段(サイズ100)</span>
              </v-col>
              <v-col
                cols="8"
              >
                <NumberInput
                  v-model="$v.ShippingForm.s100.$model"
                  :outlined="true"
                  :error-messages="s100Errors"
                  prefix="¥"
                ></NumberInput>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions align="center" class="d-flex justify-center">
          <v-btn
            color="success"
            :loading="submitStatus"
            @click="submit(ShippingForm)"
          >
            YES
          </v-btn>
          <v-btn
            class="mx-2"
            color="error"
            @click="closeForm()"
          >
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiPencil,
} from '@mdi/js'
import {
  mapMutations,
  mapActions,
  mapState,
} from 'vuex'
import {
  required,
  numeric,
} from 'vuelidate/lib/validators'
import NumberInput from '@/components/Input/NumberInput.vue'

/* eslint-disable no-useless-escape */

export default {
  components: {
    NumberInput,
  },
  props: ['id'],
  data: () => ({
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    dialog: false,
    icons: {
      mdiPencil,
    },
    submitStatus: false,
    ShippingForm: {
      // pref_names: '',
      // sf_grp_id: '',
      // sf_grp_name: '',
      // s60: '',
      // s80: '',
      // s100: '',
    },
  }),
  validations: {
    ShippingForm: {
      s60: {
        required,
        numeric,
      },
      s80: {
        required,
        numeric,
      },
      s100: {
        required,
        numeric,
      },
    },
  },
  computed: {
    ...mapState('shippingStore', ['selectedShippingInfo']),
    s60Errors() {
      const errors = []
      if (!this.$v.ShippingForm.s60.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.ShippingForm.s60.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.ShippingForm.s60.numeric && errors.push('必須数字')

      return errors
    },
    s80Errors() {
      const errors = []
      if (!this.$v.ShippingForm.s80.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.ShippingForm.s80.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.ShippingForm.s80.numeric && errors.push('必須数字')

      return errors
    },
    s100Errors() {
      const errors = []
      if (!this.$v.ShippingForm.s100.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.ShippingForm.s100.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.ShippingForm.s100.numeric && errors.push('必須数字')

      return errors
    },
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('shippingStore', ['loadShippingInfo', 'editShippingInfo']),
    openForm() {
      this.setOverlayStatus(true)
      console.log('id', this.id)
      this.loadShippingInfo(this.id).then(() => {
        this.ShippingForm = this.$deepCopy(this.selectedShippingInfo[0])
      }).catch(error => {
        if (error.response) {
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        }
      }).finally(() => {
        this.dialog = true
        this.setOverlayStatus(false)
      })
    },
    closeForm() {
      this.dialog = false
      this.$emit('refresh')
    },

    submit(ShippingForm) {
      console.log('ShippingForm:', ShippingForm)

      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitStatus = true

        this.editShippingInfo(ShippingForm)
          .then(() => {
            this.closeForm()
          })
          .catch(error => {
            if (error.response) {
              this.apierror.status = 'error'
              this.apierror.code = error.response.data.code
              this.apierror.messages = error.response.data.message
            }
          }).finally(() => {
            this.submitStatus = false
          })
      }
    },
  },
}
</script>
