<template>
  <div justify="center">
    <v-icon
      small
      @click.stop="openForm"
    >
      {{ icons.mdiDelete }}
    </v-icon>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span
            class="text-h5"
            style="color: red"
          >注文状態-削除</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="selectedStatus.os_desc"
                  label="注文状態"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="selectedStatus.os_code"
                  label="注文状態コード"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions align="center" class="d-flex justify-center">
          <v-btn
            color="success"
            @click="closeForm()"
          >
            YES
          </v-btn>
          <v-btn
            class="mx-2"
            color="error"
            @click="closeForm()"
          >
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiDelete,
} from '@mdi/js'
import {
  mapMutations,
  mapActions,
  mapState,
} from 'vuex'

export default {
  props: ['id'],
  data: () => ({
    dialog: false,
    icons: {
      mdiDelete,
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
  }),
  computed: {
    ...mapState('orderStore', ['selectedStatus']),
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('orderStore', ['loadStatus']),
    openForm() {
      this.setOverlayStatus(true)
      this.loadStatus(this.id)
        .finally(() => {
          this.dialog = true
          this.setOverlayStatus(false)
        })
    },
    closeForm() {
      this.dialog = false
    },
  },
}
</script>
