<template>
  <div justify="center">
    <v-icon
      small
      @click.stop="openForm"
    >
      {{ icons.mdiDelete }}
    </v-icon>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span
            class="text-h5"
            style="color: red"
          >クーポン-削除</span>
        </v-card-title>
        <template v-if="apierror.status == 'error'">
          <div
            v-for="msg of apierror.messages"
            :key="msg"
          >
            <v-row class="ml-6 mb-3 ma-3">
              <span style="color: red">* {{ msg }} </span>
            </v-row>
          </div>
        </template>
        <v-card-text>
          <v-container>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col cols="4" md="4">
                <span style="color:#636363">クーポン名前</span>
              </v-col>
              <v-col
                cols="8"
              >
                <v-text-field
                  v-model="selectedCoupon.coup_name"
                  readonly
                  outlined
                  dense
                  hide-details="auto"
                  color="#fa7a00"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col cols="4" md="4">
                <span style="color:#636363">クーポンコード</span>
              </v-col>
              <v-col
                cols="8"
              >
                <v-text-field
                  v-model="selectedCoupon.coup_code"
                  readonly
                  outlined
                  dense
                  hide-details="auto"
                  color="#fa7a00"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col cols="4" md="4">
                <span style="color:#636363">クーポン割引値</span>
              </v-col>
              <v-col
                cols="8"
              >
                <NumberInput
                  v-model="selectedCoupon.coup_discount"
                  :readonly="true"
                  :outlined="true"
                  prefix="¥"
                ></NumberInput>
              </v-col>
            </v-row>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col cols="4" md="4">
                <span style="color:#636363">クーポン開始日</span>
              </v-col>
              <v-col
                cols="8"
              >
                <v-menu
                  v-model="dateStartMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selectedCoupon.coup_vfd"
                      readonly
                      outlined
                      dense
                      hide-details="auto"
                      color="#fa7a00"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                </v-menu>
              </v-col>
            </v-row>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col cols="4" md="4">
                <span style="color:#636363">クーポン終了日</span>
              </v-col>
              <v-col
                cols="8"
              >
                <v-menu
                  v-model="dateEndMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selectedCoupon.coup_ved"
                      readonly
                      outlined
                      dense
                      hide-details="auto"
                      color="#fa7a00"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions align="center" class="d-flex justify-center">
          <v-btn
            color="success"
            :loading="submitStatus"
            @click="deleteItem(selectedCoupon.id)"
          >
            YES
          </v-btn>
          <v-btn
            class="mx-2"
            color="error"
            @click="closeForm()"
          >
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiDelete,
} from '@mdi/js'
import {
  mapMutations,
  mapActions,
  mapState,
} from 'vuex'
import NumberInput from '@/components/Input/NumberInput.vue'

export default {
  components: {
    NumberInput,
  },
  props: ['id'],
  data: () => ({
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    dialog: false,
    icons: {
      mdiDelete,
    },
    dateStartMenu: '',
    dateEndMenu: '',
    types: [
      {
        text: '一回切り', value: 0,
      },
      {
        text: '永久', value: 9,
      },
    ],
    submitStatus: false,
  }),
  computed: {
    ...mapState('couponStore', ['selectedCoupon']),
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('couponStore', ['loadCoupon', 'deleteCoupons']),
    openForm() {
      this.setOverlayStatus(true)
      this.loadCoupon(this.id).finally(() => {
        this.dialog = true
        this.setOverlayStatus(false)
      })
    },
    closeForm() {
      this.dialog = false
      this.$emit('refresh')
    },
    deleteItem(id) {
      this.submitStatus = true
      this.deleteCoupons(id).then(() => {
        this.closeForm()
      }).catch(error => {
        if (error.response) {
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        }
      }).finally(() => {
        this.submitStatus = false
      })
    },
  },
}
</script>
