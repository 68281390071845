<template>
  <v-row
    class="px-2 my-2"
    align="center"
  >
    <v-col
      cols="12"
      md="3"
      class="py-4"
    >
      <v-text-field
        v-model="search"
        placeholder="Search"
        outlined
        hide-details
        dense
        class="user-search me-3"
      >
      </v-text-field>
    </v-col>
    <v-spacer></v-spacer>
    <v-col
      cols="12"
      md="3"
      style="text-align:right"
    >
      <MerberStatusNew></MerberStatusNew>
    </v-col>
    <v-col
      cols="12"
      class="pa-0"
    >
      <v-data-table
        :headers="headers"
        :items="MemberStatusList"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <MerberStatusEdit
              :id="item.ms_code"
              class="py-2 pl-1"
              @click="editItem(item)"
            >
            </MerberStatusEdit>
            <MerberStatusDelete
              :id="item.ms_code"
              class="py-2 pl-1"
            ></MerberStatusDelete>
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'
import {
  mdiPlus,
  mdiPencil,
  mdiDelete,
} from '@mdi/js'
import MerberStatusNew from '@/views/bimi/setting/Master/MemberStatus/MemberStatusNew.vue'
import MerberStatusEdit from '@/views/bimi/setting/Master/MemberStatus/MemberStatusEdit.vue'
import MerberStatusDelete from '@/views/bimi/setting/Master/MemberStatus/MemberStatusDelete.vue'

// import agent from '@/api/agent'

export default {
  components: {
    MerberStatusNew,
    MerberStatusEdit,
    MerberStatusDelete,
  },
  data: () => ({
    icon: {
      mdiPlus,
      mdiPencil,
      mdiDelete,
    },
    search: '',
    headers: [
      {
        text: '会員状態コード',
        align: 'left',
        value: 'ms_code',
        width: '20%',
        fixed: true,
      },
      {
        text: '会員状態',
        align: 'left',
        value: 'ms_desc',
        width: '75%',
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '5%',
        align: 'right',
        fixed: true,
      },
    ],
  }),

  computed: {
    ...mapState('memberStore', ['MemberStatusList']),
  },

  watch: {
  },

  mounted() {
    this.$emit('has-mounted')
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('memberStore', ['loadMemberStatusList']),
    editItem(item) {
      console.log(item)
    },
    loadData() {
      this.setOverlayStatus(true)
      this.loadMemberStatusList(this.$route.query).finally(() => {
        this.setOverlayStatus(false)
      })
    },
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
</style>
