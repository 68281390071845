<template>
  <div id="currnet-stock-list">
    <v-card>
      <v-card-title>各種マスター</v-card-title>
      <v-tabs
        v-model="masterStock"
        active-class="selectedTab"
        background-color="#EDE7F6"
        slider-color="#fff"
        style="border-style: groove;border-radius: revert !important;"
      >
        <v-tab
          href="#orderStatus"
          @click="loadOrderStatus"
        >
          注文状態
        </v-tab>
        <v-tab
          href="#memberStatus"
          @click="loadMemberStatus"
        >
          会員状態
        </v-tab>
        <v-tab
          href="#prefecture"
          @click="loadPrefecture"
        >
          都道府県
        </v-tab>
        <v-tab
          href="#coupon"
          @click="loadCoupon"
        >
          クーポン
        </v-tab>
        <v-tab
          href="#shippingInfo"
          @click="loadShippingInfo"
        >
          運賃表
        </v-tab>
        <v-tab
          href="#courses"
          @click="loadCourses"
        >
          定期便コース
        </v-tab>

        <v-tabs-items
          v-model="masterStock"
          touchless
        >
          <v-tab-item value="orderStatus">
            <OrderStatusList
              ref="orderStatusList"
              @has-mounted="getOrderStatusList"
            ></OrderStatusList>
          </v-tab-item>
          <v-tab-item value="memberStatus">
            <MemberStatusList
              ref="memberStatusList"
              @has-mounted="getMemberStatusList"
            ></MemberStatusList>
          </v-tab-item>
          <v-tab-item value="prefecture">
            <Prefecture
              ref="prefecture"
              @has-mounted="getPrefecture"
            ></Prefecture>
          </v-tab-item>
          <v-tab-item value="coupon">
            <Coupon
              ref="coupon"
              @has-mounted="getCoupon"
            ></Coupon>
          </v-tab-item>
          <v-tab-item value="shippingInfo">
            <ShippingInfo
              ref="shippingInfo"
              @has-mounted="getShippingInfo"
            ></ShippingInfo>
          </v-tab-item>
          <v-tab-item value="courses">
            <Courses
              ref="courses"
              @has-mounted="getCourses"
            ></Courses>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import {
  mapMutations,
} from 'vuex'
import OrderStatusList from './OrderStatus/OrderStatusList.vue'
import MemberStatusList from './MemberStatus/MemberStatusList.vue'
import Prefecture from './Prefecture/PrefectureList.vue'
import Coupon from './Coupon/CouponList.vue'
import ShippingInfo from './ShippingInfo/ShippingInfoList.vue'
import Courses from './Courses/CoursesList.vue'

export default {
  components: {
    OrderStatusList,
    MemberStatusList,
    Prefecture,
    Coupon,
    ShippingInfo,
    Courses,
  },
  data: () => ({
    masterStock: 'orderStatus',
    OrderStatusListFlag: false,
    MemberStatusListFlag: false,
    PrefectureFlag: false,
    CouponFlag: false,
    ShippingInfoFlag: false,
    CoursesFlag: false,
  }),

  computed: {
  },

  watch: {
    masterStock(newValue) {
      this.$router.push({
        query: {
          selTab: newValue,
        },
      })
    },
  },

  mounted() {
    if (this.$route.query.selTab) {
      this.masterStock = this.$route.query.selTab
    }
    this.loadSelTab()
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    sleep(t) {
      const a = new Promise(resolve => {
        setTimeout(() => { resolve() }, t)
      })

      return a
    },

    loadSelTab() {
      if (this.masterStock === 'orderStatus') {
        this.loadOrderStatus()
      }
      if (this.masterStock === 'memberStatus') {
        this.loadMemberStatus()
      }
      if (this.masterStock === 'prefecture') {
        this.loadPrefecture()
      }
      if (this.masterStock === 'coupon') {
        this.loadCoupon()
      }
      if (this.masterStock === 'shippingInfo') {
        this.loadShippingInfo()
      }
      if (this.masterStock === 'courses') {
        this.loadCourses()
      }
    },

    getOrderStatusList() {
      this.OrderStatusListFlag = true
    },
    getMemberStatusList() {
      this.MemberStatusListFlag = true
    },
    getPrefecture() {
      this.PrefectureFlag = true
    },
    getCoupon() {
      this.CouponFlag = true
    },
    getShippingInfo() {
      this.ShippingInfoFlag = true
    },
    getCourses() {
      this.CoursesFlag = true
    },

    async loadOrderStatus() {
      this.setOverlayStatus(true)
      /* eslint-disable-next-line */
      while (!this.OrderStatusListFlag) { await this.sleep(1000) }
      this.$refs.orderStatusList.loadData()
    },
    async loadMemberStatus() {
      this.setOverlayStatus(true)
      /* eslint-disable-next-line */
      while (!this.MemberStatusListFlag) { await this.sleep(1000) }
      this.$refs.memberStatusList.loadData()
    },
    async loadPrefecture() {
      this.setOverlayStatus(true)
      /* eslint-disable-next-line */
      while (!this.PrefectureFlag) { await this.sleep(1000) }
      this.$refs.prefecture.loadData()
    },
    async loadCoupon() {
      this.setOverlayStatus(true)
      /* eslint-disable-next-line */
      while (!this.CouponFlag) { await this.sleep(1000) }
      this.$refs.coupon.loadData()
    },
    async loadShippingInfo() {
      this.setOverlayStatus(true)
      /* eslint-disable-next-line */
      while (!this.ShippingInfoFlag) { await this.sleep(1000) }
      this.$refs.shippingInfo.loadData()
    },
    async loadCourses() {
      this.setOverlayStatus(true)
      /* eslint-disable-next-line */
      while (!this.CoursesFlag) { await this.sleep(1000) }
      this.$refs.courses.loadData()
    },
  },
}
</script>

<style scoped>
.selectedTab {
  background-color: #fff;
}
.v-tabs-bar {
  border-radius: revert !important;
}
</style>
