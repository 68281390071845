<template>
  <v-row
    class="px-2 my-2"
    align="center"
  >
    <v-col
      cols="12"
      md="3"
      class="py-4"
    >
      <v-text-field
        v-model="search"
        placeholder="Search"
        outlined
        hide-details
        dense
        class="user-search me-3"
      >
      </v-text-field>
    </v-col>
    <v-spacer></v-spacer>
    <!-- <v-col
      cols="12"
      md="3"
      style="text-align:right"
    >
      <CoursesNew
        @refresh="loadData"
      ></CoursesNew>
    </v-col> -->
    <v-col
      cols="12"
      class="pa-0"
    >
      <v-data-table
        :headers="headers"
        :items="subscriptionCoursesList"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <CoursesEdit
              :id="item.course_id"
              class="py-2 pl-1"
              @refresh="loadData"
              @click="editItem(item)"
            >
            </CoursesEdit>
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'
import {
  mdiPlus,
  mdiPencil,
  mdiDelete,
} from '@mdi/js'

// import CoursesNew from '@/views/bimi/setting/Master/Courses/CoursesNew.vue'
import CoursesEdit from '@/views/bimi/setting/Master/Courses/CoursesEdit.vue'

// import agent from '@/api/agent'

export default {
  components: {
    // CoursesNew,
    CoursesEdit,
  },
  data: () => ({
    icon: {
      mdiPlus,
      mdiPencil,
      mdiDelete,
    },
    search: '',
    headers: [
      {
        text: 'ID',
        align: 'left',
        value: 'course_id',
        width: '10%',
        fixed: true,
      },
      {
        text: '表示順番',
        align: 'left',
        value: 'course_disp_order',
        width: '15%',
        fixed: true,
      },
      {
        text: 'コースコード',
        align: 'left',
        value: 'course_code',
        width: '30%',
        fixed: true,
      },
      {
        text: 'コース名前',
        align: 'left',
        value: 'course_name',
        width: '40%',
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '5%',
        align: 'right',
        fixed: true,
      },
    ],
  }),

  computed: {
    ...mapState('subscriptionStore', ['subscriptionCoursesList']),
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
  },

  watch: {
  },

  mounted() {
    this.$emit('has-mounted')
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('subscriptionStore', ['loadSubscriptionCourses']),
    editItem(item) {
      console.log(item)
    },
    loadData() {
      this.setOverlayStatus(true)
      this.loadSubscriptionCourses(this.$route.query).then(() => {
        this.subscriptionCoursesList.sort((a, b) => a.course_disp_order - b.course_disp_order)
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
</style>
