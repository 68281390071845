<template>
  <div justify="center">
    <v-btn
      color="primary"
      class="mr-2"
      @click.stop="openForm"
    >
      <v-icon
        size="17"
        class="me-1"
      >
        {{ icons.mdiPlus }}
      </v-icon>
      <span>新規</span>
    </v-btn>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span
            class="text-h5"
            style="color: red"
          >クーポン-新規</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col cols="4" md="4">
                <span style="color:#636363">クーポン名前</span><span style="color: red">*</span>
              </v-col>
              <v-col
                cols="8"
              >
                <v-text-field
                  v-model="$v.CouponForm.coup_name.$model"
                  :error-messages="coupNameErrors"
                  outlined
                  dense
                  hide-details="auto"
                  color="#fa7a00"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col cols="4" md="4">
                <span style="color:#636363">クーポンコード</span><span style="color: red">*</span>
              </v-col>
              <v-col
                cols="8"
              >
                <v-text-field
                  v-model="$v.CouponForm.coup_code.$model"
                  :error-messages="coupCodeErrors"
                  outlined
                  dense
                  hide-details="auto"
                  color="#fa7a00"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col cols="4" md="4">
                <span style="color:#636363">クーポン割引値</span><span style="color: red">*</span>
              </v-col>
              <v-col
                cols="8"
              >
                <NumberInput
                  v-model="$v.CouponForm.coup_discount.$model"
                  :outlined="true"
                  :error-messages="coupDiscountErrors"
                  prefix="¥"
                ></NumberInput>
              </v-col>
            </v-row>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col cols="4" md="4">
                <span style="color:#636363">クーポン開始日</span><span style="color: red">*</span>
              </v-col>
              <v-col
                cols="8"
              >
                <v-menu
                  v-model="dateStartMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="$v.CouponForm.coup_vfd.$model"
                      :error-messages="coupVfdErrors"
                      outlined
                      dense
                      hide-details="auto"
                      color="#fa7a00"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="$v.CouponForm.coup_vfd.$model"
                    color="#fa7a00"
                    @input="dateStartMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col cols="4" md="4">
                <span style="color:#636363">クーポン終了日</span><span style="color: red">*</span>
              </v-col>
              <v-col
                cols="8"
              >
                <v-menu
                  v-model="dateEndMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="$v.CouponForm.coup_ved.$model"
                      :error-messages="coupVedErrors"
                      outlined
                      dense
                      hide-details="auto"
                      color="#fa7a00"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="$v.CouponForm.coup_ved.$model"
                    color="#fa7a00"
                    :allowed-dates="allowedDates"
                    @input="dateEndMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <!-- <v-row align="center" class="px-2 ma-0 my-2">
              <v-col
                cols="6"
              >
                <v-autocomplete
                  v-model="$v.CouponForm.coup_type.$model"
                  :error-messages="coupTypeErrors"
                  dense
                  hide-details="auto"
                  :items="types"
                  label="クーポンタイプ"
                  color="#fa7a00"
                ></v-autocomplete>
              </v-col>
            </v-row> -->
          </v-container>
        </v-card-text>
        <v-card-actions align="center" class="d-flex justify-center">
          <v-btn
            color="success"
            :loading="submitStatus"
            @click="submit(CouponForm)"
          >
            YES
          </v-btn>
          <v-btn
            class="mx-2"
            color="error"
            @click="closeForm()"
          >
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex'
import {
  mdiPlus,
} from '@mdi/js'
import {
  required,
  maxLength,
  minLength,
  numeric,
} from 'vuelidate/lib/validators'
import {
  helpers,
} from '@vuelidate/validators'
import NumberInput from '@/components/Input/NumberInput.vue'

/* eslint-disable no-useless-escape */
// const allowedChar = helpers.regex(/^[0-9A-z!"#$%&'()*+-.,\/:;<=>?@[\]^_`{|}~]*$/)
const allowedChar = helpers.regex(/^[0-9A-Z]+[0-9A-Z!#$*+-_]*$/)

export default {
  components: {
    NumberInput,
  },
  data: () => ({
    dialog: false,
    icons: {
      mdiPlus,
    },
    dateStartMenu: '',
    dateEndMenu: '',
    types: [
      {
        text: '一回切り', value: 0,
      },
      {
        text: '永久', value: 9,
      },
    ],
    submitStatus: false,
    CouponForm: {
      coup_code: '',
      coup_name: '',
      coup_discount: '',
      coup_memo: '',
      coup_type: '',
      coup_valid: '',
      coup_ved: '',
      coup_vfd: '',
      id: '',
    },
  }),
  validations: {
    CouponForm: {
      coup_name: {
        required,
        maxLength: maxLength(100),
      },
      coup_code: {
        required,
        maxLength: maxLength(10),
        minLength: minLength(3),
        allowedChar,
      },
      coup_discount: {
        required,
        numeric,
      },
      coup_vfd: {
        required,
      },
      coup_ved: {
        required,
      },
    },
  },
  computed: {
    coupNameErrors() {
      const errors = []
      if (!this.$v.CouponForm.coup_name.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.CouponForm.coup_name.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.CouponForm.coup_name.maxLength && errors.push('100文字以内にしてください。')

      return errors
    },
    coupCodeErrors() {
      const errors = []
      if (!this.$v.CouponForm.coup_code.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.CouponForm.coup_code.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.CouponForm.coup_code.maxLength && errors.push('10文字以内にしてください。')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.CouponForm.coup_code.minLength && errors.push('3文字以上にしてください。')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.CouponForm.coup_code.allowedChar && errors.push('0-9,A-Z 半角記号(!#$*+-_)を入力してください。')

      return errors
    },
    coupDiscountErrors() {
      const errors = []
      if (!this.$v.CouponForm.coup_discount.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.CouponForm.coup_discount.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.CouponForm.coup_discount.numeric && errors.push('必須数字')

      return errors
    },
    coupVfdErrors() {
      const errors = []
      if (!this.$v.CouponForm.coup_vfd.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.CouponForm.coup_vfd.required && errors.push('必須項目')

      return errors
    },
    coupVedErrors() {
      const errors = []
      if (!this.$v.CouponForm.coup_ved.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.CouponForm.coup_ved.required && errors.push('クーポン開始日以後にしてください。')

      return errors
    },
  },
  methods: {
    ...mapActions('couponStore', ['createCoupons']),

    allowedDates(val) {
      return Date.parse(val) > Date.parse(new Date())
    },

    openForm() {
      this.dialog = true
    },
    closeForm() {
      this.dialog = false
      this.CouponForm.coup_name = ''
      this.CouponForm.coup_code = ''
      this.CouponForm.coup_discount = 0
      this.CouponForm.coup_vfd = ''
      this.CouponForm.coup_ved = ''
      this.CouponForm.coup_type = ''
      this.$emit('refresh')
      this.$v.$reset()
    },

    submit(CouponForm) {
      console.log('CouponForm', CouponForm)

      if (this.CouponForm.coup_ved < this.CouponForm.coup_vfd) {
        this.CouponForm.coup_ved = ''
      }
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitStatus = true
        this.createCoupons(CouponForm)
          .then(() => {
            this.closeForm()
          })
          .catch(error => {
            console.log(error)

            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message

            console.log('apierror', this.apierror)
          })
          .finally(() => {
            this.submitStatus = false
          })
      }
    },
  },
}
</script>
